<template>
  <div class="usersPage">
    <DuButton class="addNewUserButton" @click="goToAddOrEditUser()" text="إضافة مستخدم"/>
    <div class="usersSection">
      <DuList type="dashboardUsers" />
    </div>
  </div>
</template>

<script>
import DuButton from "../../components/DuButton";
import {reactive} from "vue";
import DuList from "../../components/DuList";
import {useRoute, useRouter} from "vue-router";
export default {
  name: "Users",
  components: {DuList, DuButton},
  setup() {
    const state = reactive({

    });

    const route = useRoute();
    const router = useRouter();

    const goToAddOrEditUser = () => {
      router.push("/controlPanel/users/addNew");
    }

    return {
      goToAddOrEditUser,
      state,
    }
  }
}
</script>

<style scoped>
.usersPage{
  display: flex;
  flex-direction: column;
}
.addNewUserButton{
  place-self: end;
  margin: 10px 1%;
}
.usersSection{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
</style>